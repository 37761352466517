@import '~antd/dist/antd.dark.less';

.app {

}

.header {
  position: fixed;
  z-index: 100;
  width: 100%;
}

.layout {
  margin-top: 64px;
}

.content {
  padding: 15px;
}

.video-player-container {
  background: #000000;
  position: relative;
  width: 100%;
  padding-top: 56.25%;
}

.video-player {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.countdown {
  background: #000000;
  position: relative;
  width: 100%;
  padding-top: 56.25%;
}

.countdown-overlay {
  backdrop-filter: blur(16px);
  border-radius: 20px;
  text-align: center;
}

.ant-statistic-title {
  color: rgba(255, 255, 255, 0.85);
}

.ant-statistic-content-value {
  color: #fff;
  font-weight: 600;
}

.countdown-container {
  background: rgba(31, 31, 31, 0.7);
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  overflow: hidden;

  background: linear-gradient(-45deg, #ee7752, #FF0048, #23a6d5, #14DCAF);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

.countdown-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.countdown-image {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}

.comments {
  border: 1px solid #1f1f1f;
  background: linear-gradient(to bottom right, #1f1f1f, #000);

  padding: 10px;
  height: calc(100vh - 94px);
}

.comment-list {
  max-height: calc(100vh - 300px);
  overflow-y:scroll
}

.comment-text-box {
  position: absolute;
  bottom: 0;
  width: calc(100% - 20px);
}

.card-container {
  padding: 0 20px 5px 20px;
}


.inner-container {
  max-width: 1200px;
  margin: auto;
}


.home-banner {
  margin: 20px;
  //border-radius: 20px;
  overflow: hidden;
  width: calc(100% - 40px);
}

.banner-overlay {
  backdrop-filter: blur(6px);
  background-color: rgba(0,0,0,0.9);
  border-radius: 20px;
  text-align: center;

  padding: 40px 60px;

  width: 100%;
  max-width: 750px;
}


@media only screen and (max-width: 2400px) {
  .comments {
    width: calc(25vw - 25px);
    position: fixed;
    bottom: 15px;
    right: 15px;
  }

  .countdown-overlay {
    padding: 40px;
    //width: 600px;
  }

  .ant-statistic-title {
    font-size: 36px;
  }

  .ant-statistic-content-value {
    font-size: 48px;
  }
}

@media only screen and (max-width: 1600px) {
  .comments {
    width: calc(33vw - 25px);
    position: fixed;
    bottom: 15px;
    right: 15px;
  }

  .countdown-overlay {
    padding: 40px;
    width: 600px;
  }

  .ant-statistic-title {
    font-size: 36px;
  }

  .ant-statistic-content-value {
    font-size: 48px;
  }
}

@media only screen and (max-width: 1200px) {
  .comments {
    width: calc(50vw - 25px);
    position: fixed;
    bottom: 15px;
    right: 15px;
  }

  .countdown-overlay {
    padding: 40px;
    width: 460px;
  }

  .ant-statistic-title {
    font-size: 30px;
  }

  .ant-statistic-content-value {
    font-size: 36px;
  }
}

@media only screen and (max-width: 992px) {
  .comments {
    width: 100%;
    position: static;
  }

  .banner-overlay {
    padding: 40px 20px;
  }

  .countdown-overlay {
    padding: 40px;
    width: 340px;
  }

  .ant-statistic-title {
    font-size: 18px;
  }

  .ant-statistic-content-value {
    font-size: 24px;
  }
}

@media only screen and (max-width: 576px) {
  .home-banner {
    margin: 0;
    border-radius: 0;
    width: 100%;
  }

  .banner-overlay {
    padding: 40px 10px;
  }

  .countdown-overlay {
    padding: 15px;
    width: 240px;
  }

  .ant-statistic-title {
    font-size: 14px;
  }

  .ant-statistic-content-value {
    font-size: 18px;
  }
}

@primary-color: #08AD9F;@switch-color: @primary-color;@menu-highlight-color: #08AD9F;@menu-item-active-bg: @item-active-bg;@menu-item-active-border-width: 3px;@btn-danger-color: #fff;@btn-danger-bg: #de0a1c;@btn-danger-border: #E60041;@layout-header-padding: 0 15px;@processing-color: #de0a1c;@card-padding-base: 0;@card-padding-base-sm: 0;@link-color: #fff;@layout-footer-background: #1f1f1f;